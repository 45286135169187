import "../styles/main.scss";
import djv from "./djv.js";
import Pristine from "./pristine.min.js";

const MobileDetect = require('mobile-detect');
const jquery = require('./jquery-3.5.1.js');
const hm = require('halfmoon');
window.Pristine = Pristine;
window.$ = window.jQuery = jquery;
require('./extends.js');

djv.debug = (process.env.NODE_ENV === 'development') ? 1 : 0;
window.djv = djv;
window.hm = hm;

window.app = {
    base: djv.debug ? 'http://127.0.0.1:8082/' : 'https://zc.jixianga.com/',
    csrf: '',
    is_send: false,
    captcha: {
        ta: null,
    },
    link: {
        init: false,
        instance: null,
    },
    err: {
        'e200': '请填写栏位。',
        'e201': '请重新整理。',
        'e300': '验证码发送过于频繁，请稍后在试。',
        'e301': '验证码发送失败，请稍后在试。',

        'e302': '验证码验证失败',
        'e303': '帐号已成功注册',
        'e304': '帐号已存在，注册失败',
        'e305': '简讯发送超过限制，请稍后再试。'
    },
    init: function () {
        this.apiUrl = `${this.base}api/`;

        app.csrf = $('#csrf_token').val();
        djv.init();
    },
    progress_btn: (btn) => {
        btn.prop('disabled', true);
    },
    done_btn: (btn) => {
        btn.prop('disabled', false);
    },
    is_ios: () => {
        const md = new MobileDetect(window.navigator.userAgent);
        const os = md.os();

        if (os === 'iOS' || os === 'iPadOS') {
            return true;
        }

        return false;
    },
    waitFor: function (condition, limit) {
        var dfr = $.Deferred();
        var times = 0;
        var during = 700;
        limit = limit || 9; // Longest duration :  during * (limit+1)

        if (Number(condition) === condition) {
            setTimeout(function () {
                dfr.resolve();
            }, condition * 1000);
        } else {
            var timer = setInterval(function () {
                times++;
                if (condition()) {
                    clearInterval(timer);
                    dfr.resolve();
                }

                if (times > limit) {
                    clearInterval(timer);
                    dfr.reject();
                }
            }, during);

        }

        return dfr.promise();
    },
    req: (url, data, callback) => {
        data.csrf = app.csrf;
        $.ajax({
            method: 'post',
            url: `${app.apiUrl}${url}`,
            data: data,
            dataType: 'json',
            crossDomain: true,
        }).done((data) => {
            app.csrf = data.csrf;

            if (data.err > 0) {
                alert(app.err[`e${data.err}`]);
            }

            callback.call(null, data);
        }).fail(() => {
            alert('系统发生错误，请重新整理');
        });
    },
    params: (key) => {
        const url = location.href;
        const params_str = url.split('?')[1];
        const query = new URLSearchParams(params_str);

        let params = {};
        for (let p of query.entries()) {
            params[p[0]] = p[1];
        }

        if (typeof key !== 'undefined' && typeof params[key] != 'undefined') {
            return params[key];
        }

        return '';
    },
    objectify: (fromArray) => {
        let rtn = {};

        for (let i = 0; i < fromArray.length; i++) {
            rtn[fromArray[i]['name']] = fromArray[i]['value'];
        }

        return rtn;
    },
    check: {
        phone: (data, btn) => {
            app.req('valid/phone', data, function (d) {
                if (d.err === 0) {
                    alert('验证码发送成功，请尽快输入。');
                    $(app.form.form).find(`[name=phone]`).prop('readonly', true);
                    app.is_send = true;
                    $('#captcha_modal').toggleClass('show', false);
                } else {
                    app.done_btn(btn);
                }
            })
        }
    },
    user: {
        register: (data, btn) => {
            data = app.objectify(data);

            app.req('user/register', data, function (d) {
                const form = $(app.form.form);

                if (d.err === 0) {
                    hm.toggleModal('success_modal');
                }

                app.done_btn(btn);
            })
        }
    }
};

djv.hook('form/valid', me => {
    const form = me.is('form') ? me : me.closest('form');
    const type = parseInt(app.params('type1')) || 2;
    const is_channel = type === 1;

    app.form = new Pristine(form.get(0), {
        errorClass: 'is-invalid',
        successClass: 'is-success',
        errorTextClass: 'invalid-feedback m-0 p-0'
    });

    const box = is_channel ? form.find('.js-channel') : form.find('.js-invite');
    box.toggleClass('hide', false).siblings('.js-channel,.js-invite')
        .toggleClass('hide', true)

    if (is_channel) {
        const input = form.find(`[name=channel]`);
        if (input) {
            input.val(app.params('pid'));
        }
    } else {
        const input = form.find(`[name=invited]`);
        if (input) {
            input.val(app.params('rid'));
        }
    }

    $('<input type="hidden" name="type">').val(type).appendTo(form);

    form.on('submit', function (e) {
        e.preventDefault();
    })
});

djv.hook('captcha/init', me => {
    const src = me.data('src');
    me.attr('src', `${app.base}${src}`);
});

djv.hook('captcha/show', me => {
    app.captcha.ta = me;
    $('#captcha_modal').toggleClass('show', true);
});

djv.hook('captcha/refresh', me => {
    const src = me.data('src');
    const time = new Date().getTime();

    me.attr('src', `${app.base}${src}?${time}`);
});

djv.hook('captcha/confirm', me => {
    let callback = app.captcha.ta.data('after');
    let input = me.closest('.modal-content').find('#captcha');
    app.captcha.val = input.val();
    app.progress_btn(me);

    if (!$.trim(app.captcha.val)) {
        app.done_btn(me);
        alert('请输入图片验证码');
        return false;
    }

    if (typeof djv.fn[callback] === 'function') {
        djv.fn[callback](app.captcha.ta, app.captcha.val);
    }
    
    app.done_btn(me);
});

djv.hook('check/phone', (me, captcha) => {
    const input = $(app.form.form).find(`[name=phone]`);
    const value = input.val();
    const errors = app.form.getErrors(input) || [];

    app.progress_btn(me);

    if (!$.trim(value) || errors.length !== 0) {
        alert('请填写手机号。');
        app.done_btn(me);
        return false;
    } else {
        app.check.phone({
            phone: value,
            captcha: captcha
        }, me);
    }
});

djv.hook('user/register', me => {
    if (app.is_send === false) {
        alert('请先获取验证码，验证手机号。');
        return false;
    }

    app.progress_btn(me);

    if (!app.form.validate()) {
        alert('请完成表单填写。');
        app.done_btn(me);
        return false;
    } else {
        const data = $(app.form.form).serializeArray();
        app.user.register(data, me);
    }
});

djv.hook('modal/close', me => {
    let modal = me.closest('.modal');
    let refresh = me.data('refresh') == '1';

    if (refresh) {
        window.location.reload(false);
        return true;
    }

    modal.toggleClass('show', false);
});

djv.hook('install/game', me => {
    if (app.is_ios()) {
        window.location.href = 'https://down.mitui88.com/EZoh.app';
    } else {
        window.location.href = 'https://mubao.lykggj.com/koudai/888gw0605.apk';
    }
});

$(document).ready(function () {
    app.init();
});
