(function (window, $, pristine) {
    $.fn.extend({
        store: function (name, value) {
            if (typeof value !== 'undefined') {
                $.fn.db.set(this, name, value);
                return $(this);
            } else {
                return $.fn.db.get(this, name);
            }
        },
        db: {
            _storage: new WeakMap(),
            set: function (ele, k, o) {
                ele = ele.get(0);
                if (!this._storage.has(ele)) {
                    this._storage.set(ele, new Map());
                }

                const map = this._storage.get(ele);
                map.set(k, o);
                this._storage.set(ele, map);
            },
            get: function (ele, k) {
                ele = ele.get(0);
                return this._storage.get(ele).get(k);
            },
            has: function (ele, k) {
                ele = ele.get(0);
                return this._storage.has(ele) && this._storage.get(ele).has(k);
            },
            remove: function (ele, k) {
                ele = ele.get(0);
                var ret = this._storage.get(ele).delete(k);
                if (!this._storage.get(k).size === 0) {
                    this._storage.delete(ele);
                }
                return ret;
            }
        }
    });

    pristine.addValidator('phone', function (value) {
        return true; // /^(1(([35789][0-9])|(47)))\d{8}$/.test(value)
    }, '请输入正确的手机号。', 2, false);

    pristine.addValidator('pwd', function (value) {
        return /^(?=.*[a-zA-Z])(?=.*\d).{5,12}$/.test(value);
        return /[0-9a-zA-Z\.\_]{5,12}/.test(value)
    }, '请输入 5 到 12 位數的英數密碼，可包含 . _。', 2, false);
})(window, window.$, Pristine);